import { Link } from "@remix-run/react";

export function Logo({ className }: { className?: string }) {
  return (
    <div>
      <Link to="/" className="flex items-center">
        <img
          src="/branding/logo_horizontal.png"
          className={className}
          alt="Logo"
        />
      </Link>
    </div>
  );
}
